import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import { useLanguage } from "../../../context/LanguageContext";
import translations from "../../../translation/translation";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./BudgetItem.css"; // Import the CSS file

const BudgetItem = () => {
  const { id } = useParams();
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.accessToken;
  const { language } = useLanguage();
  const history = useHistory();
  const t = translations[language];
  const [budgetDetails, setBudgetDetails] = useState(null);
  const [newRow, setNewRow] = useState(false);
  const [newRowData, setNewRowData] = useState({
    Discipline: "",
    BudgetCode: "",
    PrimaveraCode: "",
    JobTitle: "",
    DocumentType: "",
    Currency: "",
    UoM: "",
    StartOfWorkDate: "",
    EndOfWorkDate: "",
    EmpQty: "",
    EmpNetSalary: "",
  });

  const [disciplineOptions, setDisciplineOptions] = useState([]);
  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  const [unitOfMeasureOptions, setUnitOfMeasureOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);

  const fetchJobTitleOptions = () => {
    const url = `https://test.teamq.uz/api/gendt/budget-details/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data.Selection); // Log EmpClass here
        setJobTitleOptions(
          data.Selection.JobTitle.map((item) => {
            return {
              label: item[`JobTitle${language.toUpperCase()}`],
              value: item.id,
              EmpClass: item.EmpClass_data[`EmpClass${language.toUpperCase()}`],
              EmpLevel: item.EmpLevel_data[`EmpLevel${language.toUpperCase()}`],
            };
          })
        );
        setUnitOfMeasureOptions(
          data.Selection.UoM.map((item) => ({
            label: item[`UoM_${language.toUpperCase()}`],
            value: item.id,
          }))
        );
        setCurrencyOptions(
          data.Selection.Currency.map((item) => ({
            label: item[`Currency${language.toUpperCase()}`],
            value: item.id,
          }))
        );

        setDisciplineOptions(
          data.Selection.Discipline.map((item) => ({
            label: item[`Discipline${language.toUpperCase()}`],
            value: item.id,
          }))
        );

        setIsOptionsLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching job title options:", error);
      });
  };

  useEffect(() => {
    fetchDocumentTypeOptions();
  }, []);

  const fetchDocumentTypeOptions = () => {
    const url = `https://test.teamq.uz/api/gendt/doctype/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setDocumentTypeOptions(
          data.Response.map((item) => ({
            label: item[`DocumentType${language.toUpperCase()}`],
            value: item.id,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching document type options:", error);
        // Handle error
      });
  };

  useEffect(() => {
    if (!token) {
      console.error("No access token available.");
      history.push("/login");
      return;
    }

    const url = `https://test.teamq.uz/api/gendt/budget-details/${id}/`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("userDetails");
          history.push("/login");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setBudgetDetails(data.Response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        swal(
          "Error",
          "There was an issue with the fetch operation: " + error.message,
          "error"
        );
      });
  }, [id, token, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!budgetDetails) {
    return <div>Loading...</div>;
  }

  const handleBackClick = () => {
    history.goBack();
  };

  const handleAddClick = () => {
    setNewRow(true);
    fetchJobTitleOptions();
  };

  const handleCancelClick = () => {
    setNewRow(false);
    setNewRowData({
      Discipline: "",
      BudgetCode: "",
      PrimaveraCode: "",
      JobTitle: "",
      DocumentType: "",
      Currency: "",
      UoM: "",
      StartOfWorkDate: "",
      EndOfWorkDate: "",
      EmpQty: "",
      EmpNetSalary: "",
    });
  };

  const handleSaveClick = () => {
    const url = `https://test.teamq.uz/api/gendt/budget-details/`;

    // Map the fields to the correct names expected by the backend
    const payload = {
      Discipline: parseInt(newRowData.Discipline, 10),
      Currency: parseInt(newRowData.Currency, 10),
      UoM: parseInt(newRowData.UoM, 10),
      LegDocumentType: parseInt(newRowData.DocumentType, 10),
      JotTitle: parseInt(newRowData.JobTitle, 10),
      BudgetCode: newRowData.BudgetCode,
      PrimaveraCode: newRowData.PrimaveraCode,
      StartOfWorkDate: newRowData.StartOfWorkDate,
      EndOfWorkDate: newRowData.EndOfWorkDate,
      EmpQty: parseInt(newRowData.EmpQty, 10), // Ensure this is an integer
      EmpNetSalary: parseFloat(newRowData.EmpNetSalary), // Ensure this is a number
      Budget_ID: parseInt(id, 10), // Ensure this is an integer
    };

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Response Data:", data); // Log the response data

        if (data && data["Data is successfully saved"]) {
          // Process the response data
          const savedData = data["Data is successfully saved"];
          // Update budgetDetails state with the new data
          setBudgetDetails((prevDetails) => [...prevDetails, savedData]);
          // Reset the form
          setNewRow(false);
          setNewRowData({
            Discipline: "",
            BudgetCode: "",
            PrimaveraCode: "",
            JobTitle: "",
            DocumentType: "",
            Currency: "",
            UoM: "",
            StartOfWorkDate: "",
            EndOfWorkDate: "",
            EmpQty: "",
            EmpNetSalary: "",
          });
          swal("Success", "New budget item added successfully", "success");
        } else {
          throw new Error("Unexpected response format");
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        swal(
          "Error",
          "There was an issue with the save operation. Please try again later.",
          "error"
        );
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRowData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleJobTitleChange = (e) => {
    const { value } = e.target;
    const selectedJobTitle = jobTitleOptions.find(
      (option) => option.value === parseInt(value)
    );
    setNewRowData((prevData) => ({
      ...prevData,
      JobTitle: value,
      EmpClass: selectedJobTitle ? selectedJobTitle.EmpClass : "",
      EmpLevel: selectedJobTitle ? selectedJobTitle.EmpLevel : "",
    }));
  };

  const handleDeleteClick = (itemId) => {
    const url = `https://test.teamq.uz/api/gendt/budget-details/${itemId}/`;

    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this budget item!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(url, requestOptions)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // Update the state to remove the deleted item
            setBudgetDetails((prevDetails) =>
              prevDetails.filter((item) => item.id !== itemId)
            );
            swal("Poof! Your budget item has been deleted!", {
              icon: "success",
            });
          })
          .catch((error) => {
            console.error("Error deleting data:", error);
            swal(
              "Error",
              "There was an issue with the delete operation. Please try again later.",
              "error"
            );
          });
      }
    });
  };

  return (
    <>
      <PageTitle activeMenu="Budget Details" motherMenu="Budgets" />
      <div className="table-container">
        <h2>{t.budgetDetails}</h2>
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="budget-table display w-100">
              <thead>
                <tr>
                  <th className="bg-secondary text-nowrap">{t.discipline}</th>
                  <th className="bg-secondary text-nowrap">{t.budgetCode}</th>
                  <th className="bg-secondary text-nowrap">
                    {t.primaveraCode}
                  </th>
                  <th className="bg-secondary text-nowrap">{t.jobTitle}</th>
                  <th className="bg-secondary text-nowrap">
                    {t.employeeClass}
                  </th>
                  <th className="bg-secondary text-nowrap">
                    {t.employeeLevel}
                  </th>
                  <th className="bg-secondary text-nowrap">{t.documentType}</th>
                  <th className="bg-secondary text-nowrap">{t.currency}</th>
                  <th className="bg-secondary text-nowrap">
                    {t.unitOfMeasure}
                  </th>
                  <th className="bg-secondary text-nowrap">{t.startDate}</th>
                  <th className="bg-secondary text-nowrap">{t.endDate}</th>
                  <th className="bg-secondary text-nowrap">
                    {t.employeeQuantity}
                  </th>
                  <th className="bg-secondary text-nowrap">
                    {t.employeeSalary}
                  </th>
                  <th className="bg-secondary text-nowrap">{t.salary}</th>
                  <th className="bg-secondary text-nowrap">
                    {t.dayDifference}
                  </th>
                  <th className="bg-secondary text-nowrap">
                    {t.monthDifference}
                  </th>
                  <th className="bg-secondary text-nowrap">
                    {t.yearDifference}
                  </th>
                  <th className="bg-secondary text-nowrap">{t.taxes}</th>
                  <th className="bg-secondary text-nowrap">
                    {t.legalExpenses}
                  </th>
                  <th className="bg-secondary text-nowrap">{t.actions}</th>
                </tr>
              </thead>
              <tbody>
                {budgetDetails.map((item) => (
                  <tr key={item.id}>
                    <td>
                      {
                        item.Discipline_data?.[
                          `Discipline${language.toUpperCase()}`
                        ]
                      }
                    </td>
                    <td>{item.BudgetCode}</td>
                    <td>{item.PrimaveraCode}</td>
                    <td>
                      {item.JotTitle_data[`JobTitle${language.toUpperCase()}`]}
                    </td>
                    <td>
                      {
                        item.JotTitle_data.EmpClass_data[
                          `EmpClass${language.toUpperCase()}`
                        ]
                      }
                    </td>
                    <td>
                      {
                        item.JotTitle_data.EmpLevel_data[
                          `EmpLevel${language.toUpperCase()}`
                        ]
                      }
                    </td>
                    <td>
                      {
                        item.LegDocumentType_data[
                          `DocumentType${language.toUpperCase()}`
                        ]
                      }
                    </td>
                    <td>
                      {item.Currency_data[`Currency${language.toUpperCase()}`]}
                    </td>
                    <td>
                      {item.UoM_data[`UoM_Short_${language.toUpperCase()}`]}
                    </td>
                    <td>{item.StartOfWorkDate}</td>
                    <td>{item.EndOfWorkDate}</td>
                    <td>{item.EmpQty}</td>
                    <td>{item.EmpNetSalary}</td>
                    <td>{item.salary}</td>
                    <td>{item.day_difference}</td>
                    <td>{item.month_difference}</td>
                    <td>{item.year_difference}</td>
                    <td>{item.taxes}</td>
                    <td>{item.legal_expenses}</td>
                    <td>
                      <div className="datab">
                        <button className="btn btn-secondary shadow btn-xs sharp me-1">
                          <i className="fa fa-pencil"></i>
                        </button>
                        <button
                          className="btn btn-danger shadow btn-xs sharp"
                          onClick={() => handleDeleteClick(item.id)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
                {newRow && (
                  <tr>
                    <td>
                      {isOptionsLoaded ? (
                        <select
                          name="Discipline"
                          value={newRowData.Discipline}
                          onChange={handleInputChange}
                          className="table-input"
                        >
                          <option value="">Select Discipline</option>
                          {disciplineOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          name="Discipline"
                          value={newRowData.Discipline}
                          onChange={handleInputChange}
                          className="table-input"
                          placeholder="Loading options..."
                          disabled
                        />
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        name="BudgetCode"
                        value={newRowData.BudgetCode}
                        onChange={handleInputChange}
                        className="table-input"
                        placeholder="Enter input"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="PrimaveraCode"
                        value={newRowData.PrimaveraCode}
                        onChange={handleInputChange}
                        className="table-input"
                        placeholder="Enter input"
                      />
                    </td>
                    <td>
                      {isOptionsLoaded ? (
                        <select
                          name="JobTitle"
                          value={newRowData.JobTitle}
                          onChange={handleJobTitleChange}
                          className="table-input"
                        >
                          <option value="">Select Job Title</option>
                          {jobTitleOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          name="JobTitle"
                          value={newRowData.JobTitle}
                          onChange={handleInputChange}
                          className="table-input"
                          placeholder="Loading options..."
                          disabled
                        />
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        name="EmpClass"
                        value={newRowData.EmpClass} // Ensure EmpClass value is passed here
                        className="table-input"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="EmpLevel"
                        value={newRowData.EmpLevel}
                        className="table-input"
                        disabled
                      />
                    </td>
                    <td>
                      {isOptionsLoaded ? (
                        <select
                          name="DocumentType"
                          value={newRowData.DocumentType}
                          onChange={handleInputChange}
                          className="table-input"
                        >
                          <option value="">Select Document Type</option>
                          {documentTypeOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          name="DocumentType"
                          value={newRowData.DocumentType}
                          onChange={handleInputChange}
                          className="table-input"
                          placeholder="Loading options..."
                          disabled
                        />
                      )}
                    </td>
                    <td>
                      {isOptionsLoaded ? (
                        <select
                          name="Currency"
                          value={newRowData.Currency}
                          onChange={handleInputChange}
                          className="table-input"
                        >
                          <option value="">Select Currency</option>
                          {currencyOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          name="Currency"
                          value={newRowData.Currency}
                          onChange={handleInputChange}
                          className="table-input"
                          placeholder="Loading options..."
                          disabled
                        />
                      )}
                    </td>
                    <td>
                      {isOptionsLoaded ? (
                        <select
                          name="UoM"
                          value={newRowData.UoM}
                          onChange={handleInputChange}
                          className="table-input"
                        >
                          <option value="">Select Unit of Measure</option>
                          {unitOfMeasureOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          name="UoM"
                          value={newRowData.UoM}
                          onChange={handleInputChange}
                          className="table-input"
                          placeholder="Loading options..."
                          disabled
                        />
                      )}
                    </td>
                    <td>
                      <input
                        type="date"
                        name="StartOfWorkDate"
                        value={newRowData.StartOfWorkDate}
                        onChange={handleInputChange}
                        max={newRowData.EndOfWorkDate || ""}
                        className="table-input"
                        placeholder="Enter input"
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        name="EndOfWorkDate"
                        value={newRowData.EndOfWorkDate}
                        onChange={handleInputChange}
                        min={newRowData.StartOfWorkDate || ""}
                        className="table-input"
                        placeholder="Enter input"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="EmpQty"
                        value={newRowData.EmpQty}
                        onChange={handleInputChange}
                        className="table-input"
                        placeholder="Enter input"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="EmpNetSalary"
                        value={newRowData.EmpNetSalary}
                        onChange={handleInputChange}
                        className="table-input"
                        placeholder="Enter input"
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="button-container">
          {newRow ? (
            <>
              <button onClick={handleSaveClick} className="btn btn-success">
                Save
              </button>
              <button onClick={handleCancelClick} className="btn btn-danger">
                Cancel
              </button>
            </>
          ) : (
            <button onClick={handleAddClick} className="btn btn-primary">
              Add
            </button>
          )}
          <button
            onClick={handleBackClick}
            className="btn btn-secondary"
            style={{ marginLeft: "10px" }}
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default BudgetItem;
