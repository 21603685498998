import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Navbar,
  Nav,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { Badge } from "react-bootstrap";
import defaultImg from "../../../images/avatar/8.jpg"; // Default image
import swal from "sweetalert";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const UserInfo = ({ userData, setUserData, token, id, history }) => {
  const [photoFile, setPhotoFile] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setPhotoFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `https://test.teamq.uz/api/employee/${id}/`;

    const formData = new FormData();
    formData.append("firstname", userData.firstname);
    formData.append("surname", userData.surname);
    formData.append("other_name", userData.other_name);
    formData.append("date_of_birth", userData.date_of_birth);
    formData.append("position", userData.position);
    formData.append("mobile_number", userData.mobile_number);

    if (photoFile) {
      formData.append("photo", photoFile);
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            console.error("Error response:", errorData);
            throw new Error(`HTTP error! Status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        swal(
          "Success",
          "Personal information updated successfully!",
          "success"
        );
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        swal(
          "Error",
          "There was an issue updating the information: " + error.message,
          "error"
        );
      });
  };

  return (
    <div>
      <h5>Personal Information</h5>
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <Form.Group controlId="formFirstname">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            name="firstname"
            value={userData.firstname || ""}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="formSurname">
          <Form.Label>Surname</Form.Label>
          <Form.Control
            type="text"
            name="surname"
            value={userData.surname || ""}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="formMobileNumber">
          <Form.Label>Mobile Number</Form.Label>
          <Form.Control
            type="text"
            name="mobile_number"
            value={userData.mobile_number || ""}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="formOtherName">
          <Form.Label>Other Name</Form.Label>
          <Form.Control
            type="text"
            name="other_name"
            value={userData.other_name || ""}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="formDateOfBirth">
          <Form.Label>Date of Birth</Form.Label>
          <Form.Control
            type="date"
            name="date_of_birth"
            value={userData.date_of_birth || ""}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="formPosition">
          <Form.Label>Position</Form.Label>
          <Form.Control
            type="text"
            name="position"
            value={userData.position || ""}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="formPhoto">
          <Form.Label>Photo</Form.Label>
          <Form.Control type="file" name="photo" onChange={handleFileChange} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Save
        </Button>
        <Button
          variant="secondary"
          className="ml-2"
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
      </Form>
    </div>
  );
};

const ChangePassword = () => (
  <div>
    <h5>Change Password</h5>
    <Form>
      <Form.Group controlId="formCurrentPassword">
        <Form.Label>Current Password</Form.Label>
        <Form.Control type="password" placeholder="Enter current password" />
      </Form.Group>
      <Form.Group controlId="formNewPassword">
        <Form.Label>New Password</Form.Label>
        <Form.Control type="password" placeholder="Enter new password" />
      </Form.Group>
      <Form.Group controlId="formConfirmPassword">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control type="password" placeholder="Confirm new password" />
      </Form.Group>
      <Button variant="primary" type="submit">
        Save
      </Button>
      <Button variant="secondary" className="ml-2">
        Cancel
      </Button>
    </Form>
  </div>
);

const NotificationSettings = () => (
  <div>
    <h5>Notification Settings</h5>
    {/* Add notification settings form here */}
    <p>Notification settings content goes here.</p>
  </div>
);

///////////////////////////////////////////////

const PassportInfo = () => {
  const history = useHistory();
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.accessToken;
  const [passportData, setPassportData] = useState(null);

  useEffect(() => {
    if (!token) {
      console.error("No access token available.");
      history.push("/login");
      return;
    }

    const url = `https://test.teamq.uz/api/employee/passport/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("userDetails");
          history.push("/login");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setPassportData(data.Response[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        swal(
          "Error",
          "There was an issue with the fetch operation: " + error.message,
          "error"
        );
      });
  }, [token, history]);

  if (!passportData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h5>Passport Details</h5>
      <table>
        <tbody>
          <tr>
            <td>ID</td>
            <td>{passportData.id}</td>
          </tr>
          <tr>
            <td>Birthday</td>
            <td>{passportData.Birthday}</td>
          </tr>
          <tr>
            <td>Issue Date</td>
            <td>{passportData.IssueDate}</td>
          </tr>
          <tr>
            <td>Validity Date</td>
            <td>{passportData.ValidityDate}</td>
          </tr>
          <tr>
            <td>Letter</td>
            <td>{passportData.Letter}</td>
          </tr>
          <tr>
            <td>First Name</td>
            <td>{passportData.FirstName}</td>
          </tr>
          <tr>
            <td>Surname</td>
            <td>{passportData.Surname}</td>
          </tr>
          <tr>
            <td>Middle Name</td>
            <td>{passportData.MiddleName}</td>
          </tr>
          <tr>
            <td>Passport Series</td>
            <td>{passportData.PassportSerie}</td>
          </tr>
          <tr>
            <td>Passport Number</td>
            <td>{passportData.PassportNumber}</td>
          </tr>
          <tr>
            <td>Gender</td>
            <td>{passportData.Gender}</td>
          </tr>
          <tr>
            <td>Country Personal Number</td>
            <td>{passportData.CountryPersNo}</td>
          </tr>
          <tr>
            <td>Document Type Data</td>
            <td>{passportData.DocumentTypeData}</td>
          </tr>
          <tr>
            <td>Citizenship</td>
            <td>{passportData.citizenship}</td>
          </tr>
          <tr>
            <td>Issued By</td>
            <td>{passportData.IssuedBy}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const RegistrationInfo = () => {
  const history = useHistory();
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.accessToken;
  const [regData, setregData] = useState(null);

  useEffect(() => {
    if (!token) {
      console.error("No access token available.");
      history.push("/login");
      return;
    }

    const url = `https://test.teamq.uz/api/employee/registration/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("userDetails");
          history.push("/login");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setregData(data.Response[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        swal(
          "Error",
          "There was an issue with the fetch operation: " + error.message,
          "error"
        );
      });
  }, [token, history]);

  if (!regData) {
    return <div style={{ fontWeight: "bold", color: "black" }}>Loading...</div>;
  }

  return (
    <div>
      <h5>Registration Details</h5>
      <p>{regData.Employee_data.firstname}</p>
      <p>{regData.Employee_data.surname}</p>
      <p>{regData.Employee_data.other_name}</p>
      <p>{regData.Issued_by}</p>
      <p>{regData.Dormitory}</p>
    </div>
  );
};

const VisaInfo = () => {
  const history = useHistory();
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.accessToken;
  const [visaData, setvisaData] = useState(null);

  useEffect(() => {
    if (!token) {
      console.error("No access token available.");
      history.push("/login");
      return;
    }

    const url = `https://test.teamq.uz/api/employee/visa/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("userDetails");
          history.push("/login");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setvisaData(data.Response[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        swal(
          "Error",
          "There was an issue with the fetch operation: " + error.message,
          "error"
        );
      });
  }, [token, history]);

  if (!visaData) {
    return <div style={{ fontWeight: "bold", color: "black" }}>Loading...</div>;
  }

  return (
    <div>
      <h5>Visa Details</h5>
      <p>IssueDate: {visaData.IssueDate}</p>
      <p>FromDate: {visaData.FromDate}</p>
      <p> ValidityDate:{visaData.ValidityDate}</p>
      <p>Visa_Serie: {visaData.Visa_Serie}</p>
      <p>VisaNo: {visaData.VisaNo}</p>
      <p>DurationOfStay: {visaData.DurationOfStay}</p>
      <p>IssueBy: {visaData.IssueBy}</p>
    </div>
  );
};

const PatentInfo = () => {
  const history = useHistory();
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.accessToken;
  const [patentData, setpatentData] = useState(null);

  useEffect(() => {
    if (!token) {
      console.error("No access token available.");
      history.push("/login");
      return;
    }

    const url = `https://test.teamq.uz/api/employee/patent/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("userDetails");
          history.push("/login");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setpatentData(data.Response[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        swal(
          "Error",
          "There was an issue with the fetch operation: " + error.message,
          "error"
        );
      });
  }, [token, history]);

  if (!patentData) {
    return <div style={{ fontWeight: "bold", color: "black" }}>Loading...</div>;
  }

  return (
    <div>
      <h5>Patent Details</h5>
      <p>Job_Title: {patentData.Job_Title}</p>
      <p>SpecialMarks: {patentData.SpecialMarks}</p>
      <p>ValidTerritories: {patentData.ValidTerritories}</p>
      <p>Issued_by: {patentData.Issued_by}</p>
      <p>Register_Serial_number: {patentData.Register_Serial_number}</p>
      <p>ValidityDate: {patentData.ValidityDate}</p>
      <p>IssueDate: {patentData.IssueDate}</p>
      <p>DocumentNo: {patentData.DocumentNo}</p>
      <p>DocumentSerie: {patentData.DocumentSerie}</p>
    </div>
  );
};

const Medinfo = () => {
  const history = useHistory();
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.accessToken;
  const [medData, setmedData] = useState(null);

  useEffect(() => {
    if (!token) {
      console.error("No access token available.");
      history.push("/login");
      return;
    }

    const url = `https://test.teamq.uz/api/employee/med/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("userDetails");
          history.push("/login");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setmedData(data.Response[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        swal(
          "Error",
          "There was an issue with the fetch operation: " + error.message,
          "error"
        );
      });
  }, [token, history]);

  if (!medData) {
    return <div style={{ fontWeight: "bold", color: "black" }}>Loading...</div>;
  }

  return (
    <div>
      <h5>Med Details</h5>
      <p>IssueDate: {medData.IssueDate}</p>
      <p>FromDate: {medData.FromDate}</p>
      <p>Doc_Type_En: {medData.Doc_Type_En_data.Doc_Type_En}</p>
      <p>IssuedBy: {medData.IssuedBy}</p>
    </div>
  );
};

const MigrInfo = () => {
  const history = useHistory();
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.accessToken;
  const [migrData, setmigrData] = useState(null);

  useEffect(() => {
    if (!token) {
      console.error("No access token available.");
      history.push("/login");
      return;
    }

    const url = `https://test.teamq.uz/api/employee/migr-card/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("userDetails");
          history.push("/login");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setmigrData(data.Response[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        swal(
          "Error",
          "There was an issue with the fetch operation: " + error.message,
          "error"
        );
      });
  }, [token, history]);

  if (!migrData) {
    return <div style={{ fontWeight: "bold", color: "black" }}>Loading...</div>;
  }

  return (
    <div>
      <h5>Migr Details</h5>
      <p>Border checkpoint: {migrData.BorderCheckpoint}</p>
      <p>Name and Surname: {migrData.NameSurname}</p>
      <p>From: {migrData.From}</p>
      <p> To: {migrData.To}</p>
      <p>Purpose: {migrData.VisitPurpose}</p>
    </div>
  );
};

/////////////////////////////////////////
const EmployeeDocuments = () => (
  <div>
    <PassportInfo />
    <hr />
    <RegistrationInfo />
    <hr />
    <VisaInfo />
    <hr />
    <PatentInfo />
    <hr />
    <Medinfo />
    <hr />
    <MigrInfo />
  </div>
);

const Delegations = () => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h5>Certificates</h5>
        <Button variant="primary" className="mb-3">
          Add New Certificates
        </Button>
      </div>
      <table className="table table-striped table-bordered table-responsive">
        <thead>
          <tr>
            <th>User</th>
            <th>Delegate</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Asrorbek Tursunpulatov</td>
            <td>Qobiljon Tulaganov</td>
            <td>22 July 2022 10:25</td>
            <td>07 August 2022 11:25</td>
            <td>
              <Badge variant="success">Success</Badge>
            </td>
            <td className="d-flex">
              <Button variant="warning" size="sm">
                <i className="fa fa-pencil"></i>
              </Button>
              <Button variant="danger" size="sm" className="ml-2">
                <i className="fa fa-trash"></i>
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const TenderTemplates = () => (
  <div>
    <h5>Cost Centre Transfer History</h5>
    <p>Cost Centre Transfer History content goes here.</p>
  </div>
);

const PersonalInformation = () => {
  const { id } = useParams();
  const history = useHistory();
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.accessToken;
  const [activeContent, setActiveContent] = useState("user-info");
  const [expanded, setExpanded] = useState(false);
  const [userData, setUserData] = useState({});
  const [profileImage, setProfileImage] = useState(defaultImg);

  useEffect(() => {
    if (!token) {
      console.error("No access token available.");
      history.push("/login");
      return;
    }

    const url = `https://test.teamq.uz/api/employee/${id}/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("userDetails");
          history.push("/login");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setUserData(data.Response);
        // Set profile image if available in user data
        if (data.Response.profileImage) {
          setProfileImage(data.Response.profileImage);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        swal(
          "Error",
          "There was an issue with the fetch operation: " + error.message,
          "error"
        );
      });
  }, [token]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLinkClick = () => {
    setExpanded(false);
  };

  const renderContent = () => {
    switch (activeContent) {
      case "user-info":
        return (
          <UserInfo
            userData={userData}
            setUserData={setUserData}
            token={token}
            id={id}
            history={history}
          />
        );
      case "change-password":
        return <ChangePassword />;
      case "notification-settings":
        return <NotificationSettings />;
      case "user-favorite-list":
        return <EmployeeDocuments />;
      case "delegations":
        return <Delegations />;
      case "tender-templates":
        return <TenderTemplates />;
      default:
        return null;
    }
  };

  return (
    <Row>
      {/* Navbar for mobile */}
      <Navbar expand="lg" className="w-100 d-lg-none">
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className={expanded ? "show" : ""}
        >
          <Nav className="mr-auto flex-column">
            <Nav.Link
              onClick={() => {
                setActiveContent("user-info");
                handleLinkClick();
              }}
            >
              Personal Information
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                setActiveContent("change-password");
                handleLinkClick();
              }}
            >
              Change Password
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                setActiveContent("notification-settings");
                handleLinkClick();
              }}
            >
              Notification Settings
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                setActiveContent("user-favorite-list");
                handleLinkClick();
              }}
            >
              Employment Documents
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                setActiveContent("delegations");
                handleLinkClick();
              }}
            >
              Certificates
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                setActiveContent("tender-templates");
                handleLinkClick();
              }}
            >
              Cost Centre Transfer History
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* Sidebar for desktop */}
      <Col lg={3} className="navbar__style p-3 d-none d-lg-block">
        <div className="text-center">
          <img
            src={profileImage}
            alt="Profile"
            className="img-fluid rounded-circle mb-2 profile-image"
            style={{ width: "150px", height: "150px", objectFit: "cover" }}
          />
          <h5>Asrorbek Tursunpulatov</h5>
          <p className="text-muted text-over" title="asrorobek0204@gmail.com">
            asrorobek0204@gmail.com
          </p>
          <div className="d-flex align-items-center justify-content-between">
            <p>System Language:</p>
            <p>English</p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p>Timezone:</p>
            <p> Europe/Moscow</p>
          </div>
        </div>
        <hr />
        <Nav className="flex-column">
          <Nav.Link
            onClick={() => setActiveContent("user-info")}
            className="text-decoration-none"
          >
            Personal Information
          </Nav.Link>
          <Nav.Link
            onClick={() => setActiveContent("change-password")}
            className="text-decoration-none"
          >
            Change Password
          </Nav.Link>
          <Nav.Link
            onClick={() => setActiveContent("notification-settings")}
            className="text-decoration-none"
          >
            Notification Settings
          </Nav.Link>
          <Nav.Link
            onClick={() => setActiveContent("user-favorite-list")}
            className="text-decoration-none"
          >
            Employment Documents
          </Nav.Link>
          <Nav.Link
            onClick={() => setActiveContent("delegations")}
            className="text-decoration-none"
          >
            Certificates
          </Nav.Link>
          <Nav.Link
            onClick={() => setActiveContent("tender-templates")}
            className="text-decoration-none"
          >
            Cost Centre Transfer History
          </Nav.Link>
        </Nav>
      </Col>

      {/* Main content area */}
      <Col lg={9} className="p-3">
        {renderContent()}
      </Col>
    </Row>
  );
};

export default PersonalInformation;
