import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import { useLanguage } from "../../../context/LanguageContext";
import translations from "../../../translation/translation";
import { Badge } from "react-bootstrap";

const EmployeeList = () => {
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.accessToken;
  const history = useHistory();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [contents, setContents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const { language } = useLanguage();
  const t = translations[language];
  const [canGoNext, setCanGoNext] = useState(false);
  const [filters, setFilters] = useState({
    surname: "",
    firstname: "",
    position: "",
    personnel_number: "",
    mobile_number: "",
  });

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleRowSelect = (employeeId) => {
    setSelectedItems((prevSelectedItems) => {
      const itemIndex = prevSelectedItems.findIndex(
        (item) => item.id === employeeId
      );
      if (itemIndex !== -1) {
        const updatedSelectedItems = prevSelectedItems.filter(
          (item) => item.id !== employeeId
        );
        console.log(updatedSelectedItems);
        return updatedSelectedItems;
      } else {
        const selectedItem = contents.find(
          (content) => content.id === employeeId
        );
        const updatedSelectedItems = [...prevSelectedItems, selectedItem];
        console.log(updatedSelectedItems);
        return updatedSelectedItems;
      }
    });
  };

  const isRowSelected = (employeeId) => {
    return selectedItems.find((item) => item.id === employeeId);
  };

  const handleSelectAll = () => {
    const allSelected = selectedItems.length === contents.length;
    if (allSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(contents.slice());
    }
  };

  const handleEditClick = (employee) => {
    setSelectedEmployee(employee);
    setShowEditModal(true);
  };

  const handleInfoClick = (employee) => {
    history.push(`/employee-list/${employee.id}/employee-information`);
  };

  useEffect(() => {
    if (!token) {
      console.error("No access token available.");
      history.push("/login");
      return;
    }

    const url = `https://test.teamq.uz/api/employee/?page=${currentPage}&limit=${itemsPerPage}`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("userDetails");
          history.push("/login");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setContents(data.results);
        setTotalItems(data.count);
        setCanGoNext(data.next != null);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        swal(
          "Error",
          "There was an issue with the fetch operation: " + error.message,
          "error"
        );
      });
  }, [token, history, currentPage, itemsPerPage]);

  const downloadEmployeeData = () => {
    if (!token) {
      console.error("No access token available.");
      return;
    }

    const selectedIds = selectedItems.map((item) => item.id);

    if (selectedIds.length === 0) {
      swal("Error", "Please select at least one item to download.", "error");
      return;
    }

    let url = "https://test.teamq.uz/api/employee/";
    if (selectedIds.length > 0) {
      url += `?excel=${selectedIds.join(",")}`;
    } else {
      url += "?excel=True";
    }

    console.log(url);

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "employee_data.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
        swal(
          "Error",
          "Failed to download employee data: " + error.message,
          "error"
        );
      });
  };

  const handleAddEmployee = () => {
    history.push("/employees/add-employee");
  };

  const handleDeleteClick = (employeeId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this employee!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `https://test.teamq.uz/api/employee/${employeeId}/`;
        const requestOptions = {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        fetch(url, requestOptions)
          .then((response) => {
            if (!response.ok) throw new Error("Failed to delete the employee");
            return response.json();
          })
          .then(() => {
            setContents(contents.filter((item) => item.id !== employeeId));
            swal("Poof! Your employee has been deleted!", {
              icon: "success",
            });
          })
          .catch((error) => {
            console.error("Error deleting employee:", error);
            swal("Error", "Failed to delete employee", "error");
          });
      }
    });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const renderTableRows = () => {
    const filteredContents = contents
      .filter((content) => {
        return Object.keys(filters).every((key) => {
          if (!filters[key]) return true;
          const contentValue = content[key]
            ? content[key].toString().toLowerCase()
            : "";
          const filterValue = filters[key].toLowerCase();
          return contentValue.includes(filterValue);
        });
      })
      .filter((content) => {
        if (statusFilter) {
          const contentStatus = content.current_status
            ? content.current_status.toLowerCase()
            : "";
          const filterStatus = statusFilter.toLowerCase();
          return contentStatus === filterStatus;
        } else {
          return true;
        }
      });

    return filteredContents.map((content) => (
      <tr className="" key={content.id}>
        <td>
          <input
            type="checkbox"
            checked={isRowSelected(content.id)}
            onChange={() => handleRowSelect(content.id)}
          />
        </td>
        <td>{content.personnel_number}</td>
        <td>{content.surname}</td>
        <td>{content.firstname}</td>
        <td>{content.position}</td>
        <td>{content.mobile_number}</td>
        <td>
          <Badge
            variant={content.current_status === "active" ? "success" : "danger"}
          >
            {content.current_status}
          </Badge>
        </td>
        <td className="datab">
          <button
            className="btn btn-secondary shadow btn-xs sharp me-1"
            onClick={() => handleEditClick(content)}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button
            className="btn btn-danger shadow btn-xs sharp me-1"
            onClick={() => handleDeleteClick(content.id)}
          >
            <i className="fa fa-trash"></i>
          </button>
          <button
            className="btn btn-success shadow btn-xs sharp"
            onClick={() => handleInfoClick(content)}
          >
            <i className="fa fa-info"></i>
          </button>
        </td>
      </tr>
    ));
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1); // Reset to first page when items per page change
  };

  return (
    <>
      <PageTitle activeMenu={t.employees} motherMenu={t.dashboard} />
      <div>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">{t["employeesList"]}</h4>
            <div className="btn-wrapper">
              <Button
                className="btn btn-primary"
                onClick={downloadEmployeeData}
              >
                <i className="flaticon-028-download"></i>{" "}
                {t["downloadEmployee"]}
              </Button>
            </div>
          </div>
          <div className="card-body">
            <div className="w-100 table-responsive">
              <div className="d-flex align-items-center justify-content-between">
                <div className="form-group d-flex align-items-center">
                  <label htmlFor="itemsPerPageSelect" className="m-0 me-2">
                    {t["employeePerPage"]}:
                  </label>
                  <select
                    className="form-control"
                    style={{ width: "100px" }}
                    onChange={handleItemsPerPageChange}
                    value={itemsPerPage}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                  </select>
                </div>
                <Button
                  className="btn btn-primary ms-2"
                  onClick={handleAddEmployee}
                >
                  <i className="flaticon-067-plus"></i> {t["addEmployee"]}
                </Button>
              </div>
              <table className="display w-100 dataTable">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={
                          selectedItems.length === contents.length &&
                          contents.length > 0
                        }
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th>{t["personal_number"]}</th>
                    <th>{t["surname"]}</th>
                    <th>{t["first_name"]}</th>
                    <th>{t["position"]}</th>
                    <th>{t["mobile_number"]}</th>
                    <th>{t["current_status"]}</th>
                    <th className="datab">{t["action"]}</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>
                      <input
                        type="text"
                        className="form-control border border-info"
                        name="personnel_number"
                        value={filters.personnel_number}
                        onChange={handleFilterChange}
                        placeholder={t["personal_number"]}
                      />
                    </th>
                    <th>
                      <input
                        type="text"
                        className="form-control border border-info"
                        name="surname"
                        value={filters.surname}
                        onChange={handleFilterChange}
                        placeholder={t["surname"]}
                      />
                    </th>
                    <th>
                      <input
                        type="text"
                        className="form-control border border-info"
                        name="firstname"
                        value={filters.firstname}
                        onChange={handleFilterChange}
                        placeholder={t["first_name"]}
                      />
                    </th>
                    <th>
                      <input
                        type="text"
                        className="form-control border border-info"
                        name="position"
                        value={filters.position}
                        onChange={handleFilterChange}
                        placeholder={t["position"]}
                      />
                    </th>
                    <th>
                      <input
                        type="text"
                        className="form-control border border-info"
                        name="mobile_number"
                        value={filters.mobile_number}
                        onChange={handleFilterChange}
                        placeholder={t["mobile_number"]}
                      />
                    </th>
                    <th>
                      <select
                        className="form-control border border-info"
                        name="current_status"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                      >
                        <option value="">{t["all"]}</option>
                        <option value="active">{t["active"]}</option>
                        <option value="inactive">{t["inactive"]}</option>
                      </select>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
              </table>
              <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                <h5 className="m-0">
                  {t.pagination} {currentPage} {t.paginationOf} {totalPages}
                </h5>
                <div className="d-flex">
                  <button className="btn btn-primary" onClick={handleSelectAll}>
                    {selectedItems.length === contents.length
                      ? t["deselect_all"]
                      : t["select_all"]}
                  </button>
                  <button
                    className={`btn btn-primary ms-2 ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    {t.previous}
                  </button>
                  <button
                    className={`btn btn-primary ms-2 ${
                      !canGoNext ? "disabled" : ""
                    }`}
                    disabled={!canGoNext}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    {t.next}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeList;
