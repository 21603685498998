import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import { useLanguage } from "../../../context/LanguageContext";
import translations from "../../../translation/translation";
import { Badge } from "react-bootstrap";
import { getDecodedRefreshTokenFromLocalStorage } from "../../../jwt/jwtDecoder";

const ProjectsList = () => {
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.accessToken;
  const history = useHistory();
  const [selectedProject, setSelectedProject] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [contents, setContents] = useState([]);
  const [filters, setFilters] = useState({
    ProjectNameEN: "",
    AddressEN: "",
    StartDate: "",
    EndDate: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(1);
  const [canGoNext, setCanGoNext] = useState(false);
  const { language } = useLanguage(); // This should get the current language
  const t = translations[language];
  const [selectedItems, setSelectedItems] = useState([]);
  const decodedToken = getDecodedRefreshTokenFromLocalStorage("userDetails");
  const userID = decodedToken.payload.user_id;
  const [showModal, setShowModal] = useState(false);
  const [newProject, setNewProject] = useState({
    ProjectID_1C: "",
    ProjectCode: "",
    ProjectNameEN: "",
    ProjectNameRU: "",
    ProjectNameTR: "",
    AddressEN: "",
    AddressRU: "",
    AddressTR: "",
    StartDate: "",
    EndDate: "",
    Author: userID,
    SubjectofRF: "",
  });
  const [subjectOptions, setSubjectOptions] = useState([]);

  const fetchSubjects = async () => {
    try {
      const response = await fetch(
        "https://test.teamq.uz/api/gendt/subject-rf/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch subjects");
      }
      const data = await response.json();
      setSubjectOptions(data.Response);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  const handleEditClick = (project) => {
    setSelectedProject(project);
    setShowEditModal(true);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    if (!token) {
      console.error("No access token available.");
      history.push("/login");
      return;
    }

    // Directly use currentPage as the page number
    const url = `https://test.teamq.uz/api/gendt/project/?page=${currentPage}&limit=${itemsPerPage}`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("userDetails");
          history.push("/login");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setContents(
          data.results.map((item) => ({
            id: item.id,
            ProjectNameEN: item.ProjectNameEN,
            AddressEN: item.AddressEN,
            StartDate: item.StartDate,
            EndDate: item.EndDate,
            ProjectID_1C: item.ProjectID_1C,
            ProjectCode: item.ProjectCode,
            ProjectNameRU: item.ProjectNameRU,
            ProjectNameTR: item.ProjectNameTR,
            AddressRU: item.AddressRU,
            AddressTR: item.AddressTR,
            SubjectofRF: item.SubjectofRF,
          }))
        );
        setTotalItems(data.count);
        setCanGoNext(data.next != null); // If 'next' URL is not null, there are more pages
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        swal(
          "Error",
          "There was an issue with the fetch operation: " + error.message,
          "error"
        );
      });
  }, [token, history, currentPage, itemsPerPage]);

  const downloadProjects = () => {
    if (!token) {
      console.error("No access token available.");
      return;
    }

    // Get the IDs of selected items
    const selectedIds = selectedItems.map((item) => item.id);

    if (selectedIds.length === 0) {
      // Show an error message if no items are selected
      swal("Error", "Please select at least one item to download.", "error");
      return;
    }

    let url = "https://test.teamq.uz/api/gendt/projects/";
    if (selectedIds.length > 0) {
      url += `?excel=${selectedIds.join(",")}`;
    } else {
      url += "?excel=True";
    }

    console.log(url);

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "project.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove(); // Cleanup
        window.URL.revokeObjectURL(url); // Free up memory
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
        swal(
          "Error",
          "Failed to download budget data: " + error.message,
          "error"
        );
      });
  };

  const handleRowSelect = (projectId) => {
    setSelectedItems((prevSelectedItems) => {
      const itemIndex = prevSelectedItems.findIndex(
        (item) => item.id === projectId
      );
      if (itemIndex !== -1) {
        const updatedSelectedItems = prevSelectedItems.filter(
          (item) => item.id !== projectId
        );
        console.log(updatedSelectedItems);
        return updatedSelectedItems;
      } else {
        const selectedItem = contents.find(
          (content) => content.id === projectId
        );
        const updatedSelectedItems = [...prevSelectedItems, selectedItem];
        console.log(updatedSelectedItems);
        return updatedSelectedItems;
      }
    });
  };

  const isRowSelected = (projectId) => {
    return selectedItems.find((item) => item.id === projectId);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewProject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddProject = (event) => {
    event.preventDefault();
    const url = "https://test.teamq.uz/api/gendt/project/";
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newProject),
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to create new project");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); // Check what the data actually looks like
        setShowModal(false);
        swal("Success", "Project added successfully!", "success").then(() => {
          setContents((prev) => [...prev, data]);
          window.location.reload();
        });
      })

      .catch((error) => {
        swal("Error", "Failed to add project: " + error.message, "error");
      });
  };

  const handleUpdateProject = () => {
    const url = `https://test.teamq.uz/api/gendt/project/${selectedProject.id}/`;
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedProject),
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setShowEditModal(false);
        swal("Success", "Project updated successfully!", "success").then(() => {
          setContents((prev) =>
            prev.map((item) => (item.id === data.id ? data : item))
          );
          window.location.reload();
        });
      })
      .catch((error) => {
        console.error("Error updating project:", error);
        swal("Error", "Failed to update project", "error");
      });
  };

  const handleProjectChange = (event) => {
    const { name, value } = event.target;
    setSelectedProject((prevProject) => ({
      ...prevProject,
      [name]: value,
    }));
  };

  const handleDeleteClick = (projectId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this project!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `https://test.teamq.uz/api/gendt/project/${projectId}/`;
        const requestOptions = {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        fetch(url, requestOptions)
          .then((response) => {
            if (!response.ok) throw new Error("Failed to delete the project");
            return response.json();
          })
          .then(() => {
            console.log(contents);
            setContents(contents.filter((item) => item.id !== projectId));
            swal("Poof! Your project has been deleted!", {
              icon: "success",
            });
          })
          .catch((error) => {
            console.error("Error deleting project:", error);
            swal("Error", "Failed to delete project", "error");
          });
      }
    });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const renderTableRows = () => {
    const filteredContents = contents.filter((content) => {
      return Object.keys(filters).every((key) => {
        if (!filters[key]) return true; // Skip if filter is empty
        // Handle potential null or undefined data gracefully
        const contentValue = content[key]
          ? content[key].toString().toLowerCase()
          : "";
        const filterValue = filters[key].toLowerCase();
        return contentValue.includes(filterValue);
      });
    });

    return filteredContents.map((content) => (
      <tr key={content.id}>
        <td>
          <input
            type="checkbox"
            checked={isRowSelected(content.id)}
            onChange={() => handleRowSelect(content.id)}
          />
        </td>
        <td>{content.ProjectNameEN}</td>
        <td>{content.AddressEN}</td>
        <td>{content.StartDate}</td>
        <td>{content.EndDate}</td>
        <td>
          <Badge variant="success light">Successful</Badge>
        </td>
        <td className="datab">
          <button
            className="btn btn-secondary shadow btn-xs sharp me-2"
            onClick={() => handleEditClick(content)}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button
            className="btn btn-danger shadow btn-xs sharp"
            onClick={() => handleDeleteClick(content.id)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </td>
      </tr>
    ));
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page with new page size
  };

  const handleSelectAll = () => {
    const allSelected = selectedItems.length === contents.length;
    if (allSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(contents.slice());
    }
  };

  return (
    <>
      <PageTitle activeMenu={t.listProjects} motherMenu={t.projects} />
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{t.projectDatatables}</h4>
          <div className="btn-wrapper">
            <Button className="btn btn-info ms-1" onClick={downloadProjects}>
              <i
                className="flaticon-028-download
"
              ></i>{" "}
              {t.downloadProjects}
            </Button>
          </div>
        </div>
        <div className="card-body">
          <div className="w-100 table-responsive">
            <div className="d-flex align-items-center justify-content-between">
              <div className="form-group d-flex align-items-center">
                <label htmlFor="itemsPerPageSelect" className="m-0 me-2">
                  {t.projectsPerPage}:
                </label>
                <select
                  className="form-control"
                  style={{
                    width: "100px",
                  }}
                  onChange={handleItemsPerPageChange}
                  value={itemsPerPage}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
              </div>

              <Button
                onClick={() => setShowModal(true)}
                className="btn btn-info"
              >
                <i className="flaticon-067-plus"></i> {t.addProject}
              </Button>
            </div>
            <table className="display w-100 dataTable ">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>{t.projectName}</th>
                  <th>{t.address}</th>
                  <th>{t.startDate}</th>
                  <th>{t.endDate}</th>
                  <th>Status</th>
                  <th>{t.action}</th>
                </tr>
                <tr>
                  <th></th>
                  <th>
                    <input
                      type="text"
                      className="form-control border border-info"
                      name="ProjectNameEN"
                      value={filters.ProjectNameEN}
                      onChange={handleFilterChange}
                      placeholder="Project Name"
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      className="form-control border border-info"
                      name="AddressEN"
                      value={filters.AddressEN}
                      onChange={handleFilterChange}
                      placeholder="Address"
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      className="form-control border border-info"
                      name="StartDate"
                      value={filters.StartDate}
                      onChange={handleFilterChange}
                      placeholder="Start Date"
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      className="form-control border border-info"
                      name="EndDate"
                      value={filters.EndDate}
                      onChange={handleFilterChange}
                      placeholder="End Date"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
              <h5 className="m-0">
                {t.pagination} {currentPage} {t.paginationOf} {totalPages}
              </h5>

              <div className="d-flex ">
                {" "}
                <button className="btn btn-primary" onClick={handleSelectAll}>
                  {selectedItems.length === contents.length
                    ? "Deselect All"
                    : "Select All"}
                </button>
                <button
                  className={`btn btn-primary ms-2 ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  {t.previous}
                </button>
                <button
                  className={`btn btn-primary ms-2 ${
                    !canGoNext ? "disabled" : ""
                  }`}
                  disabled={!canGoNext}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  {t.next}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Add New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="ProjectID_1C">
              <Form.Label>Project ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Project ID"
                name="ProjectID_1C"
                value={newProject.ProjectID_1C}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="ProjectCode">
              <Form.Label>Project Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Project Code"
                name="ProjectCode"
                value={newProject.ProjectCode}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="ProjectNameEN">
              <Form.Label>Project Name (EN)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter English Project Name"
                name="ProjectNameEN"
                value={newProject.ProjectNameEN}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="ProjectNameRU">
              <Form.Label>Project Name (RU)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Russian Project Name"
                name="ProjectNameRU"
                value={newProject.ProjectNameRU}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="ProjectNameTR">
              <Form.Label>Project Name (TR)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Turkish Project Name"
                name="ProjectNameTR"
                value={newProject.ProjectNameTR}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="AddressEN">
              <Form.Label>Address (EN)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter English Address"
                name="AddressEN"
                value={newProject.AddressEN}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="AddressRU">
              <Form.Label>Address (RU)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Russian Address"
                name="AddressRU"
                value={newProject.AddressRU}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="AddressTR">
              <Form.Label>Address (TR)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Turkish Address"
                name="AddressTR"
                value={newProject.AddressTR}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="StartDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                name="StartDate"
                value={newProject.StartDate}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="EndDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                name="EndDate"
                value={newProject.EndDate}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formSubjectofRF">
              <Form.Label>Subject of RF</Form.Label>
              <Form.Control
                as="select"
                name="SubjectofRF"
                value={newProject.SubjectofRF}
                onChange={handleInputChange}
              >
                <option value="">Select Subject</option>
                {subjectOptions.map((subject) => (
                  <option key={subject.id} value={subject.id}>
                    {subject[`SubjectOfRF_${language.toUpperCase()}`]}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddProject}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header>
          <Modal.Title>Add New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="ProjectID_1C">
              <Form.Label>Project ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Project ID"
                name="ProjectID_1C"
                value={selectedProject?.ProjectID_1C || ""}
                onChange={(e) => handleProjectChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="ProjectCode">
              <Form.Label>Project Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Project Code"
                name="ProjectCode"
                value={selectedProject?.ProjectCode || ""}
                onChange={(e) => handleProjectChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="ProjectNameEN">
              <Form.Label>Project Name (EN)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter English Project Name"
                name="ProjectNameEN"
                value={selectedProject?.ProjectNameEN || ""}
                onChange={(e) => handleProjectChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="ProjectNameRU">
              <Form.Label>Project Name (RU)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Russian Project Name"
                name="ProjectNameRU"
                value={selectedProject?.ProjectNameRU || ""}
                onChange={(e) => handleProjectChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="ProjectNameTR">
              <Form.Label>Project Name (TR)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Turkish Project Name"
                name="ProjectNameTR"
                value={selectedProject?.ProjectNameTR || ""}
                onChange={(e) => handleProjectChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="AddressEN">
              <Form.Label>Address (EN)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter English Address"
                name="AddressEN"
                value={selectedProject?.AddressEN || ""}
                onChange={(e) => handleProjectChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="AddressRU">
              <Form.Label>Address (RU)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Russian Address"
                name="AddressRU"
                value={selectedProject?.AddressRU || ""}
                onChange={(e) => handleProjectChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="AddressTR">
              <Form.Label>Address (TR)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Turkish Address"
                name="AddressTR"
                value={selectedProject?.AddressTR || ""}
                onChange={(e) => handleProjectChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="StartDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                name="StartDate"
                value={selectedProject?.StartDate || ""}
                onChange={(e) => handleProjectChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="EndDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                name="EndDate"
                value={selectedProject?.EndDate || ""}
                onChange={(e) => handleProjectChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="formSubjectofRF">
              <Form.Label>Subject of RF</Form.Label>
              <Form.Control
                as="select"
                name="SubjectofRF"
                value={selectedProject?.SubjectofRF || ""}
                onChange={handleProjectChange}
              >
                <option value="">Select Subject</option>
                {subjectOptions.map((subject) => (
                  <option key={subject.id} value={subject.id}>
                    {subject.SubjectOfRF_EN}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateProject}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectsList;
