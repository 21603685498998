import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import { useLanguage } from "../../../context/LanguageContext";
import translations from "../../../translation/translation";
import { getDecodedRefreshTokenFromLocalStorage } from "../../../jwt/jwtDecoder";
import { Badge } from "react-bootstrap";

const BudgetList = () => {
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.accessToken;
  const history = useHistory();
  const [selectedProject, setSelectedProject] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [contents, setContents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const { language } = useLanguage();
  const t = translations[language];
  const [canGoNext, setCanGoNext] = useState(false);
  const [filters, setFilters] = useState({
    VersionDate: "",
    Author: "",
    TotalCost: "",
    ProjectName: "",
    BudgetVersion: "",
  });
  const [dataFilter, setDataFilter] = useState("all");
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleRowSelect = (projectId) => {
    setSelectedItems((prevSelectedItems) => {
      const itemIndex = prevSelectedItems.findIndex(
        (item) => item.id === projectId
      );
      if (itemIndex !== -1) {
        const updatedSelectedItems = prevSelectedItems.filter(
          (item) => item.id !== projectId
        );
        console.log(updatedSelectedItems);
        return updatedSelectedItems;
      } else {
        const selectedItem = contents.find(
          (content) => content.id === projectId
        );
        const updatedSelectedItems = [...prevSelectedItems, selectedItem];
        console.log(updatedSelectedItems);
        return updatedSelectedItems;
      }
    });
  };

  const isRowSelected = (projectId) => {
    return selectedItems.find((item) => item.id === projectId);
  };

  const handleSelectAll = () => {
    const allSelected = selectedItems.length === contents.length;
    if (allSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(contents.slice());
    }
  };

  const handleEditClick = (project) => {
    setSelectedProject(project);
    setShowEditModal(true);
  };

  const handleInfoClick = (project) => {
    history.push(`/budgets/${project.id}/budget-details`);
  };

  useEffect(() => {
    if (!token) {
      console.error("No access token available.");
      history.push("/login");
      return;
    }

    const url = `https://test.teamq.uz/api/gendt/budget-data/?page=${currentPage}&limit=${itemsPerPage}&${dataFilter}=True`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("userDetails");
          history.push("/login");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setContents(
          data.results.map((item) => ({
            id: item.id,
            Author: item.Author_data.username,
            BudgetVersion: item.BudgetVersion,
            TotalCost: item.total_cost,
            ProjectName: item.Project_data.ProjectNameEN,
            VersionDate: item.VersionDate,
            EndOfWorkDate: item.Project_data.EndDate,
            Status: item.Status,
          }))
        );
        setTotalItems(data.count);
        setCanGoNext(data.next != null);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        swal(
          "Error",
          "There was an issue with the fetch operation: " + error.message,
          "error"
        );
      });
  }, [token, history, currentPage, itemsPerPage, dataFilter]);

  const downloadBudget = () => {
    if (!token) {
      console.error("No access token available.");
      return;
    }

    // Get the IDs of selected items
    const selectedIds = selectedItems.map((item) => item.id);

    if (selectedIds.length === 0) {
      // Show an error message if no items are selected
      swal("Error", "Please select at least one item to download.", "error");
      return;
    }

    let url = "https://test.teamq.uz/api/gendt/budget-data/";
    if (selectedIds.length > 0) {
      url += `?excel=${selectedIds.join(",")}`;
    } else {
      url += "?excel=True";
    }

    console.log(url);

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "budget.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove(); // Cleanup
        window.URL.revokeObjectURL(url); // Free up memory
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
        swal(
          "Error",
          "Failed to download budget data: " + error.message,
          "error"
        );
      });
  };

  const handleAddProject = () => {
    history.push("/budgets/add-budget");
  };

  const handleDeleteClick = (projectId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this project!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `https://test.teamq.uz/api/gendt/budget-data/${projectId}/`;
        const requestOptions = {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        fetch(url, requestOptions)
          .then((response) => {
            if (!response.ok) throw new Error("Failed to delete the project");
            return response.json();
          })
          .then(() => {
            setContents(contents.filter((item) => item.id !== projectId));
            swal("Poof! Your project has been deleted!", {
              icon: "success",
            });
          })
          .catch((error) => {
            console.error("Error deleting project:", error);
            swal("Error", "Failed to delete project", "error");
          });
      }
    });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleDataFilterChange = (event) => {
    setDataFilter(event.target.value);
  };

  const renderTableRows = () => {
    const filteredContents = contents
      .filter((content) => {
        return Object.keys(filters).every((key) => {
          if (!filters[key]) return true;
          const contentValue = content[key]
            ? content[key].toString().toLowerCase()
            : "";
          const filterValue = filters[key].toLowerCase();
          return contentValue.includes(filterValue);
        });
      })
      .filter((content) => {
        if (statusFilter) {
          const contentStatus = content.Status
            ? content.Status.toLowerCase()
            : "";
          const filterStatus = statusFilter.toLowerCase();
          return contentStatus === filterStatus;
        } else {
          return true;
        }
      });

    return filteredContents.map((content) => (
      <tr className="" key={content.id}>
        <td>
          <input
            type="checkbox"
            checked={isRowSelected(content.id)}
            onChange={() => handleRowSelect(content.id)}
          />
        </td>
        <td>{content.ProjectName}</td>
        <td>{content.BudgetVersion}</td>
        <td>{content.VersionDate}</td>
        <td>{content.Author}</td>
        <td>{content.TotalCost}</td>
        <td>
          <Badge variant={content.Status === "success" ? "success" : "danger"}>
            {content.Status}
          </Badge>
        </td>
        <td className="datab">
          <button
            className="btn btn-secondary shadow btn-xs sharp me-1"
            onClick={() => handleEditClick(content)}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button
            className="btn btn-danger shadow btn-xs sharp"
            onClick={() => handleDeleteClick(content.id)}
          >
            <i className="fa fa-trash"></i>
          </button>
          <button
            className="btn btn-warning shadow btn-xs sharp ms-1"
            onClick={() => handleInfoClick(content)}
          >
            <i className="fa fa-info"></i>
          </button>
        </td>
      </tr>
    ));
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page with new page size
  };

  return (
    <>
      <PageTitle activeMenu={t.budgets} motherMenu={t.projects} />
      <div>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">{t.budgetData}</h4>
            <div className="btn-wrapper ">
              <Button className="btn btn-info ms-1" onClick={downloadBudget}>
                <i className="flaticon-028-download"></i> {t.downloadBudgets}
              </Button>
            </div>
          </div>
          <div className="card-body">
            <div className="w-100 table-responsive">
              <div className="d-flex align-items-center justify-content-between">
                <div className="form-group d-flex align-items-center">
                  <label htmlFor="itemsPerPageSelect" className="m-0 me-2">
                    {t.budgetsPerPage}:
                  </label>
                  <select
                    className="form-control"
                    style={{
                      width: "100px",
                    }}
                    onChange={handleItemsPerPageChange}
                    value={itemsPerPage}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>

                  <div className="d-flex me-3">
                    <div className="form-check me-3 ms-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="dataFilter"
                        id="latest"
                        value="latest"
                        checked={dataFilter === "latest"}
                        onChange={handleDataFilterChange}
                      />
                      <label className="form-check-label" htmlFor="latest">
                        {t.latest}
                      </label>
                    </div>
                    <div className="form-check me-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="dataFilter"
                        id="archived"
                        value="archived"
                        checked={dataFilter === "archived"}
                        onChange={handleDataFilterChange}
                      />
                      <label className="form-check-label" htmlFor="archived">
                        {t.archive}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="dataFilter"
                        id="all"
                        value="all"
                        checked={dataFilter === "all"}
                        onChange={handleDataFilterChange}
                      />
                      <label className="form-check-label" htmlFor="all">
                        {t.all}
                      </label>
                    </div>
                  </div>
                </div>

                <Button
                  onClick={() => handleAddProject()}
                  className="btn btn-info"
                >
                  <i className="flaticon-067-plus"></i> {t.addBudget}
                </Button>
              </div>
              <table className="display w-100 dataTable ">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Project Name</th>
                    <th>{t.budgetVersion}</th>
                    <th>Version Date</th>
                    <th>Author</th>
                    <th>Total Cost</th>
                    <th>Status</th>
                    <th>{t.action}</th>
                  </tr>
                  <tr>
                    <th></th>

                    <th>
                      <input
                        type="text"
                        className="form-control border border-info"
                        name="ProjectName"
                        value={filters.ProjectName}
                        onChange={handleFilterChange}
                        placeholder="Project Name"
                      />
                    </th>
                    <th>
                      <input
                        type="text"
                        className="form-control border border-info"
                        name="BudgetVersion"
                        value={filters.BudgetVersion}
                        onChange={handleFilterChange}
                        placeholder="Budget Version"
                      />
                    </th>
                    <th>
                      <input
                        type="text"
                        className="form-control border border-info"
                        name="VersionDate"
                        value={filters.VersionDate}
                        onChange={handleFilterChange}
                        placeholder="Version Date"
                      />
                    </th>
                    <th>
                      <input
                        type="text"
                        className="form-control border border-info"
                        name="Author"
                        value={filters.Author}
                        onChange={handleFilterChange}
                        placeholder="Author"
                      />
                    </th>
                    <th>
                      <input
                        type="text"
                        className="form-control border border-info"
                        name="TotalCost"
                        value={filters.TotalCost}
                        onChange={handleFilterChange}
                        placeholder="Total Cost"
                      />
                    </th>
                    <th>
                      <input
                        type="text"
                        className="form-control border border-info"
                        name="TotalCost"
                        value={filters.TotalCost}
                        onChange={handleFilterChange}
                        placeholder="Total Cost"
                      />
                    </th>
                    <th>
                      <select
                        className="form-control border border-info"
                        name="Status"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="Successful">Successful</option>
                        <option value="Pending">Pending</option>
                        {/* Add more options for other status values */}
                      </select>
                    </th>
                  </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
              </table>
              <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                <h5 className="m-0">
                  {t.pagination} {currentPage} {t.paginationOf} {totalPages}
                </h5>

                <div className="d-flex ">
                  {" "}
                  <button className="btn btn-primary" onClick={handleSelectAll}>
                    {selectedItems.length === contents.length
                      ? "Deselect All"
                      : "Select All"}
                  </button>
                  <button
                    className={`btn btn-primary ms-2 ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    {t.previous}
                  </button>
                  <button
                    className={`btn btn-primary ms-2 ${
                      !canGoNext ? "disabled" : ""
                    }`}
                    disabled={!canGoNext}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    {t.next}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetList;

{
  /* <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header>
          <Modal.Title>Edit Budget</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="BudgetVersion">
              <Form.Label>Budget Version</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Budget Version"
                name="BudgetVersion"
                value={selectedProject?.BudgetVersion || ""}
                onChange={(e) => handleBudgetChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="BudgetCode">
              <Form.Label>Budget code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Budget Version"
                name="BudgetCode"
                value={selectedProject?.BudgetCode || ""}
                onChange={(e) => handleBudgetChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="PrimaveraCode">
              <Form.Label>PrimaveraCode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Primavera Code"
                name="PrimaveraCode"
                value={selectedProject?.PrimaveraCode || ""}
                onChange={(e) => handleBudgetChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="StartOfWorkDate">
              <Form.Label>Start Of Work Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter English Project Name"
                name="StartOfWorkDate"
                value={selectedProject?.StartOfWorkDate || ""}
                onChange={(e) => handleBudgetChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="EndOfWorkDate">
              <Form.Label>EndOfWorkDate</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter Russian Project Name"
                name="EndOfWorkDate"
                value={selectedProject?.EndOfWorkDate || ""}
                onChange={(e) => handleBudgetChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="EmpQty">
              <Form.Label>EmpQty</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Turkish Project Name"
                name="EmpQty"
                value={selectedProject?.EmpQty || ""}
                onChange={(e) => handleBudgetChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="EmpNetSalary">
              <Form.Label>EmpNetSalary</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter English Address"
                name="EmpNetSalary"
                value={selectedProject?.EmpNetSalary || ""}
                onChange={(e) => handleBudgetChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="Discipline">
              <Form.Label>Discipline</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Russian Address"
                name="Discipline"
                value={selectedProject?.Discipline || ""}
                onChange={(e) => handleBudgetChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="JotTitle">
              <Form.Label>JotTitle</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Turkish Address"
                name="JotTitle"
                value={selectedProject?.JotTitle || ""}
                onChange={(e) => handleBudgetChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="LegDocumentType">
              <Form.Label>LegDocumentType</Form.Label>
              <Form.Control
                type="number"
                name="LegDocumentType"
                value={selectedProject?.LegDocumentType || ""}
                onChange={(e) => handleBudgetChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="Currency">
              <Form.Label>Currency</Form.Label>
              <Form.Control
                type="number"
                name="Currency"
                value={selectedProject?.Currency || ""}
                onChange={(e) => handleBudgetChange(e)}
              />
            </Form.Group>
            <Form.Group controlId="UoM">
              <Form.Label>UoM</Form.Label>
              <Form.Control
                type="number"
                name="UoM"
                value={selectedProject?.UoM || ""}
                onChange={(e) => handleBudgetChange(e)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateProject}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */
}
