// jwtDecoder.js
import jwt from "jsonwebtoken";

// Function to decode a JWT without verifying the signature
function decodeJWT(token) {
  const decodedToken = jwt.decode(token, { complete: true });
  if (!decodedToken) {
    throw new Error("Invalid token");
  }
  return decodedToken;
}

// Function to get and decode the refresh token from local storage
function getDecodedRefreshTokenFromLocalStorage(key) {
  const userDetails = JSON.parse(localStorage.getItem(key)); // Retrieve userDetails object from local storage
  if (userDetails && userDetails.refreshToken) {
    const decodedToken = decodeJWT(userDetails.refreshToken);
    return {
      header: decodedToken.header,
      payload: decodedToken.payload,
      signature: decodedToken.signature,
    };
  } else {
    console.error("Refresh token not found in local storage");
    return null;
  }
}

export { getDecodedRefreshTokenFromLocalStorage };
