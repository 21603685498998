// translation/translations.js
const translations = {
  en: {
    searchPlaceholder: "Search here...",
    dashboard: "Dashboard",
    generateReport: "Generate Report",
    projects: "Projects",
    listProjects: "List Projects",
    budgets: "Budgets",
    ganttChart: "Gantt Chart",
    laborDemand: "Labor Demand List",
    myTasks: "My Tasks",
    kanbanBoard: "Kanban Board",
    pendingTasks: "Pending Tasks",
    employee: "Employee",
    employeeGeneralList: "Employee General List",
    employeeCard: "Employee Card",
    personalInformation: "Personal Information",
    employeeDocuments: "Employee Documents",
    certificates: "Certificates",
    costCentreTransferHistory: "Cost Centre Transfer History",
    timesheet: "Timesheet",
    payroll: "Payroll",
    dashboards: "Dashboards",
    statistics: "Statistics",
    analyticalReports: "Analytical Reports",
    projectDatatables: "Project Datatable",
    downloadProjects: "Download Project",
    addProject: "Add Project",
    projectsPerPage: "Projects Per Page",
    projectName: "Project Name",
    address: "Address",
    startDate: "Start Date",
    endDate: "End Date",
    action: "Action",
    budgetData: "Budget Data",
    downloadBudgets: "Download Budgets",
    addBudget: "Add Budget",
    budgetsPerPage: "Budgets Per Page",
    budgetVersion: "Budget Version",
    budgetCode: "Budget Code",
    next: "Next Page",
    previous: "Previous Page",
    salary: "Salary",
    primaveraCode: "Primavera Code",
    pagination: "Page",
    paginationOf: "of",
    discipline: "Discipline",
    jobTitle: "Job Title",
    employeeClass: "Employee Class",
    employeeLevel: "Employee Level",
    documentType: "Document Type",
    currency: "Currency",
    unitOfMeasure: "Unit of Measure",
    startDate: "Start Date",
    endDate: "End Date",
    employeeQuantity: "Employee Quantity",
    employeeSalary: "Employee Salary",
    dayDifference: "Day difference",
    monthDifference: "Month difference",
    yearDifference: "Year difference",
    taxes: "Taxes",
    legalExpenses: "Legal Expenses",
    actions: "Actions",
    select_all: "Select All",
    deselect_all: "Deselect All",
    all: "All",
    active: "Active",
    inactive: "Inactive",
    personal_number: "Personal Number",
    surname: "Surname",
    first_name: "First Name",
    position: "position",
    mobile_number: "Mobile Number",
    current_status: "Status",
    employeePerPage: "Employee Per Page",
    addEmployee: "Add Employee",
    downloadEmployee: "Download Employee",
    employeesList: "Employees List",
    employees: "Employees",
    latest: "Latest",
    archive: "Archive",
  },
  tr: {
    searchPlaceholder: "Burada ara...",
    dashboard: "Tableau de bord",
    generateReport: "Générer le rapport",
    projects: "Projeler",
    listProjects: "Proje Listesi",
    budgets: "Bütçeler",
    ganttChart: "Gantt Çizelgesi",
    laborDemand: "İşgücü Talep Listesi",
    myTasks: "Görevlerim",
    kanbanBoard: "Kanban Panosu",
    pendingTasks: "Bekleyen Görevler",
    employee: "Çalışan",
    employeeGeneralList: "Genel Çalışan Listesi",
    employeeCard: "Çalışan Kartı",
    personalInformation: "Kişisel Bilgiler",
    employeeDocuments: "Çalışan Belgeleri",
    certificates: "Sertifikalar",
    costCentreTransferHistory: "Maliyet Merkezi Transfer Geçmişi",
    timesheet: "Zaman Çizelgesi",
    payroll: "Maaş Bordrosu",
    dashboards: "Panolar",
    statistics: "İstatistikler",
    analyticalReports: "Analitik Raporlar",
    projectDatatables: "Proje Veri Tablosu",
    downloadProjects: "Projeyi indir",
    addProject: "Proje ekle",
    projectsPerPage: "Sayfa başına projeler",
    projectName: "Proje Adı",
    address: "Adres",
    startDate: "Başlangıç ​​tarihi",
    endDate: "Bitiş tarihi",
    action: "Aksiyon",
    budgetData: "Bütçe Verileri",
    downloadBudgets: "Bütçeleri İndir",
    addBudget: "Bütçe Ekle",
    budgetsPerPage: "Sayfa Başına Bütçeler",
    budgetVersion: "Bütçe Versiyonu",
    budgetCode: "Bütçe Kodu",
    next: "Sonraki Sayfa",
    previous: "Önceki sayfa",
    salary: "Maaş",
    primaveraCode: "Primavera Kodu",
    pagination: "Sayfa",
    paginationOf: "/",
    discipline: "Disiplin",
    jobTitle: "İş Başlığı",
    employeeClass: "Çalışan Sınıfı",
    employeeLevel: "Çalışan Seviyesi",
    documentType: "Belge Türü",
    currency: "Para Birimi",
    unitOfMeasure: "Ölçü Birimi",
    employeeQuantity: "Çalışan Miktarı",
    employeeSalary: "Çalışan Maaşı",
    dayDifference: "Gün Farkı",
    monthDifference: "Ay Farkı",
    yearDifference: "Yıl Farkı",
    taxes: "Vergiler",
    legalExpenses: "Yasal Giderler",
    actions: "Eylemler",
    select_all: "Tümünü Seç",
    deselect_all: "Seçimi Kaldır",
    all: "Hepsi",
    active: "Aktif",
    inactive: "Pasif",
    personal_number: "Personel Numarası",
    surname: "Soyadı",
    first_name: "Adı",
    position: "Pozisyon",
    mobile_number: "Cep Telefonu",
    current_status: "Durum",
    employeePerPage: "Sayfa Başına Çalışan",
    addEmployee: "Çalışan Ekle",
    downloadEmployee: "Çalışan İndir",
    employeesList: "Çalışan Listesi",
    employees: "Çalışanlar",
    latest: "Aktif",
    archive: "Arşiv",
  },
  ru: {
    searchPlaceholder: "Поищи здесь...",
    dashboard: "Tableau de bord",
    generateReport: "Générer le rapport",
    projects: "Проекты",
    listProjects: "Список проектов",
    budgets: "Бюджеты",
    ganttChart: "Ганттовская диаграмма",
    laborDemand: "Список потребностей в рабочей силе",
    myTasks: "Мои задачи",
    kanbanBoard: "Доска Канбан",
    pendingTasks: "Задачи в ожидании",
    employee: "Сотрудник",
    employeeGeneralList: "Общий список сотрудников",
    employeeCard: "Карточка сотрудника",
    personalInformation: "Личная информация",
    employeeDocuments: "Документы сотрудников",
    certificates: "Сертификаты",
    costCentreTransferHistory: "История переводов по центрам затрат",
    timesheet: "Табель учета рабочего времени",
    payroll: "Расчетная ведомость",
    dashboards: "Панели управления",
    statistics: "Статистика",
    analyticalReports: "Аналитические отчеты",
    projectDatatables: "Таблица данных проекта",
    downloadProjects: "Скачать проект",
    addProject: "Добавить проект",
    projectsPerPage: "Проектов на странице",
    projectName: "Название проекта",
    address: "Адрес",
    startDate: "Дата начала",
    endDate: "Дата окончания",
    action: "Действие",
    budgetData: "Данные бюджета",
    downloadBudgets: "Скачать бюджеты",
    addBudget: "Добавить бюджет",
    budgetsPerPage: "Бюджетов на странице",
    budgetVersion: "Версия бюджета",
    budgetCode: "Код бюджета",
    next: "Следующий",
    previous: "Предыдущий",
    salary: "Зарплата",
    primaveraCode: "Код Примавера",
    pagination: "Страница",
    paginationOf: "из",
    discipline: "Дисциплина",
    jobTitle: "Должность",
    employeeClass: "Класс сотрудника",
    employeeLevel: "Уровень сотрудника",
    documentType: "Тип документа",
    currency: "Валюта",
    unitOfMeasure: "Единица измерения",
    employeeQuantity: "Количество сотрудников",
    employeeSalary: "Заработная плата сотрудника",
    dayDifference: "Разница в днях",
    monthDifference: "Разница в месяцах",
    yearDifference: "Разница в годах",
    taxes: "Налоги",
    legalExpenses: "Юридические расходы",
    actions: "Действия",
    select_all: "Выбрать все",
    deselect_all: "Отменить выбор",
    all: "Все",
    active: "Активный",
    inactive: "Неактивный",
    personal_number: "Личный номер",
    surname: "Фамилия",
    first_name: "Имя",
    position: "Должность",
    mobile_number: "Мобильный номер",
    current_status: "Статус",
    employeePerPage: "Сотрудников на странице",
    addEmployee: "Добавить сотрудника",
    downloadEmployee: "Скачать данные сотрудника",
    employeesList: "Список сотрудников",
    employees: "Сотрудники",
    latest: "Последний",
    archive: "Архив",
  },
};

export default translations;
